const Portfolio_data = [
  {
    id: 1,
    category: "Implementation services",
    title: "Streamlining Your Transactions for Maximum Efficiency",
    image:
      "https://res.cloudinary.com/dmuslrdni/image/upload/v1709511669/oneTechnologies/latest%20images/brx99m5k1jndbmsajfom.png",
    description:
      "A successful implementation requires a blend of in-depth experience and enhanced skills. Our end-to-end implementations incorporate established methodologies derived from our combined technical and domain experience. Our extensive understanding of Temenos products allows our clients to utilize the internal workings of the product without sacrificing any of their needs.",
    conc:
      "1 Technologies Ltd follows the proven Temenos TIM implementation methodology to deliver projects within budget, on target and with superior quality.",
  },
  {
    id: 2,
    category: "Upgrade and Technology Migration services",
    title: "Customizing Software to Boost Your Productivity",
    image:
      "https://res.cloudinary.com/dmuslrdni/image/upload/v1709511668/oneTechnologies/latest%20images/im27djopywp4ueu3djny.png",
    description:
      "Regular updates are necessary for applications to support banks' efforts to provide new features, comply with regulations, satisfy customer expectations, stay current with technology advancements, cut expenses, and enhance security. Frequent Temenos application upgrades assist in meeting the demands of the banks and their clients, both now and in the future.",
    conc:
      "To handle your upgrade engagements, our certified upgrade specialists are highly skilled and knowledgeable. Our services include technology migrations (desktop to browser / UXP/ Transact Explorer, TAFC to TAFJ, jBase to a market leading RDBMs), release upgrades (going from a lower to a higher Temenos product release), and module upgrades (technical upgrades + incorporation of new modules in latest releases).",
  },
  {
    id: 3,
    category: "Model Bank implementation",
    title: "Seamless Migration to Streamline Your Operations",
    image:
      "https://res.cloudinary.com/dmuslrdni/image/upload/v1709511668/oneTechnologies/latest%20images/lk7nsyzdlrczmiaq1tuz.png",
    description:
      "Do you want to get into the market as soon as possible? With its pre-built, reliable processes and configurations, Temenos Model Bank is a ready-to-deploy core banking suite that will help you fulfill your ROI targets and get started quickly with a top core banking application.",
    conc: "With the least amount of customization necessary, our skilled team of Lead Business and Technical Consultants has implemented greenfield banks based on the T24 Model Bank. ",
  },
  {
    id: 4,
    category: "Customisation (L3) services",
    title: "Effortless Payments for Seamless Transactions",
    image:
      "https://res.cloudinary.com/dmuslrdni/image/upload/v1709511668/oneTechnologies/latest%20images/djkjteq4cyf12rbq2jil.png",
    description:
      "1 Technologies Ltd follows the proven Temenos TIM implementation methodology to deliver projects within budget, on target and with superior quality.",
    conc:
      "With the highest level of quality, 1 Tech's skilled development team offers L3 customization and other non-core services.",
  },
  {
    id: 5,
    category: "Migration services",
    title: "Ensuring Quality Thorough Testing Services Offered",
    image:
      "https://res.cloudinary.com/dmuslrdni/image/upload/v1709511669/oneTechnologies/latest%20images/rq0ikvntw3jlymewfysx.png",
    description:
      "Data migration becomes a hurdle for any core banking transformation project as data gets more dispersed across multiple systems and information security rules tighten even further. Success can be guaranteed by strong approach, experience, and stringent governance. ",
    conc:
      "Our techno-functional team, which consists of migration professionals with experience and talent in project management and business analysts with deep subject expertise, leads our migration services. Our group adheres to a reliable migration process that comprises distinct stages, tasks, and deliverables. We can take on an end-to-end data migration task as a stand-alone engagement or as a component of our Implementation Services.",
  },
  {
    id: 6,
    category: "QA & Testing services",
    title: "Empowering Your Team Through Comprehensive Training",
    image:
      "https://res.cloudinary.com/dmuslrdni/image/upload/v1709511669/oneTechnologies/latest%20images/sbdc2shf5xv0m7k3salv.png",
    description:
      "Our techno-functional team, which consists of migration professionals with experience and talent in project management and business analysts with deep subject expertise, leads our migration services. Our group adheres to a reliable migration process that comprises distinct stages, tasks, and deliverables. We can take on an end-to-end data migration task as a stand-alone engagement or as a component of our Implementation Services.",
    conc:
      "Our techno-functional team at 1 Tech oversees our QA & Testing services. They are composed of QA Analysts with proficiency in test automation and management and Business Analysts with deep domain understanding. Our clients use our test automation services as value-added assets to fulfill project deadlines and reduce project risks without sacrificing quality.",
  },
  {
    id: 7,
    category: "Training & Documentation services",
    title: "Empowering Your Team Through Comprehensive Training",
    image:
      "https://res.cloudinary.com/dmuslrdni/image/upload/v1709511669/oneTechnologies/latest%20images/wuf2so2scvpuharkqxkb.png",
    description:
      "The business readiness stage of large projects is vital. For end users to use the programs efficiently, they must receive sufficient training. Thorough training initiatives lead to fewer errors, increased self-assurance among employees, and enhanced workplace conditions. Standard operating procedures, user manuals, and any other paperwork that may be required by bank internal policies must be prepared in order to ensure end-user readiness in addition to training.",
    conc:
      "To assist end customers in comprehending the features and functioning of Temenos products and utilizing the application appropriately, we offer tailored training packages. Our Lead Business Consultants are adept at explaining and producing user manuals and operational procedures for online learning environments.",
  },
  {
    id: 8,
    category: "Staff Augmentation services",
    title: "Empowering Your Team Through Comprehensive Training",
    image:
      "https://res.cloudinary.com/dmuslrdni/image/upload/v1709511669/oneTechnologies/latest%20images/nntjc8huynrtypx6avnr.png",
    description:
      "To meet your goals, we provide customizable engagement and resource models. Our resources have extensive domain knowledge from their experience in the banking sector, which they transferred to Temenos product installations. Our Lead Business Consultants oversee consulting and business analysis services. We have Senior Technical resources with substantial years of experience in numerous Temenos applications and other cutting-edge technologies to supplement our business competency.",
    conc:
      "There are several models available to meet your needs. Various instances of effectively using Onsite, Offshore, and Hybrid models to meet client requirements have been documented. ",
  },
];
export default Portfolio_data;
