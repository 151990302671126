import React, { useState } from "react";

const Card = (props) => {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }
 

  return (
    <>
      <div onClick={toggleModal} className="box btn_shadow ">
        <div className="img">
          <img src={props.image} alt=""  />
        </div>
        <div className="category d_flex">
          <span >{props.category}</span>
        </div>
        <div className="title">
          <a href="#popup" className="arrow" >
            <i class="fas fa-arrow-right"></i>
          </a>
        </div>
      </div>

      {/* Popup box */}
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content ">
            <div className="modal-img ">
              <img src={props.image} alt="" />
            </div>
            <div className="modal-text ">
              <span>{props.category}</span>
              <br />
              <br />
              <p>{props.description}</p>
              <p>{props.conc}</p>
              <button className="close-modal btn_shadow" onClick={toggleModal}>
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Card;
