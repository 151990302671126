import React from "react";
import logo1 from "./pic/logo.png";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container text-center top">
          <hr />

          <div className="img">
            <img src={logo1} width="200px" alt="" />
          </div>
          <div className="footer-mp ">
            <div className="col_1">
              <h4 clas>Catch US</h4>
              <div className="button">
                <div className="button">
                  <div className="centers">
                    <i class="fas fa-map-marker-alt"></i>
                    <p>
                      House No:-44/i, Flat No:-2A,Block-D, Road-4, Bashundhara
                      R/A, Dhaka-1229
                    </p>
                  </div>
                  <div className="centers">
                    <i class="far fa-envelope"></i>
                    <p>info@1technologiesltd.com</p>
                  </div>
                  {/* <div className="centers">
                    <i class="fas fa-phone"></i>
                    <p>+00 65 6980 6260</p>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="col_1">
              <h4>Useful Link</h4>
              <div className="button">
                <a href="#services">
                  <button className="btn_shadow ml">
                    <i className="fas fa-link "></i>
                  </button>
                </a>
              </div>
            </div>

            <div className="col_1">
              <h4>FIND US</h4>
              <div className="button">
                <a href="https://www.facebook.com/1technologiesltd">
                  <button className="btn_shadow">
                    <i className="fab fa-facebook-f"></i>
                  </button>
                </a>
                <a href="https://www.linkedin.com/company/1technologiesltd">
                  <button className="btn_shadow">
                    <i className="fab fa-linkedin-in"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="Footer__last__item">
            <p>
              © {new Date().getFullYear() + 2} All rights reserved by 1
              Technologies
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
