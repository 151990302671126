import React from "react";
import "./HeroSection.css";
// import hero from "../pic/hero9.png";

const HeroSection = () => {
  return (
    <>
      <div id="heros">
        <div className="slider">
          <div className="carousel-item ">
            {/* <img src={hero} alt="..." /> */}
          </div>
        </div>

        <div className="red-sec "></div>
        <div className="lrb"></div>
        <div className="lgb"></div>
        <div className="l-box">
          <div className="s-down hide">
            <a href="#">We Provide Best Service</a>
          </div>
        </div>
        <div className="intro-box">
          <div className="in-title ">Banking Solution</div>
          <div className="in-txt">
            <h5>1</h5>
            <h5>Technologies</h5>
          </div>
          <div className="in-b-txt ">
            Reachable Newest Digital Banking Technologies
          </div>

          <a
            href="#vision"
            className="button-block button-block--brand active "
          >
            get started
          </a>
        </div>
      </div>
      <div className="Vision__button">
        <span> ↓</span>
      </div>
    </>
  );
};

export default HeroSection;
