import React from "react";
import "./Career.css";

const Career = () => {
  return (
    <>
      <div className="heading text-center ">
        <h4>Paths to Professional Fulfillment Ahead</h4>
        <h1>Career</h1>
      </div>
      <div id="career" className="Career__body">
        <section className="Career__section Career__light">
          <div className="Career__box">
            <h2>Benefits</h2>
            <br />
            <br />

            <p>✓ Yearly Bonus </p>
            <p>✓ Flexible Timing </p>
            <p>✓ Joyful workplace</p>
          </div>
        </section>

        <section className="Career__section" data-theme="Career__dark">
          <div className="Career__box  Career__box__text">
            <h2>Current Offerings</h2>
            <br />
            <br />
            <h4></h4>
            <h4> </h4>
            <h4></h4>
            <p> ✓ Devops Engineer</p>
            <p> ✓ Cloud Engineer</p>
            <p> ✓ Kubernetes Engineer</p>
          </div>
        </section>
      </div>
    </>
  );
};

export default Career;
