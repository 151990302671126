import React from "react";
import Header from "./component/Head/Header";
import Services from "./component/Services/Services";
import Contact from "./component/Contact/Contact";
import Footer from "./component/Footer";
import "./App.css";
import HeroSection from "./component/HeroSection/HeroSection";
import Vision from "./component/Vision/Vision";
import Career from "./component/Career/Career";

const App = () => {
  return (
    <>
      <Header />
      <HeroSection />
      <Vision />
      <Services />
      <Career />
      <Contact />
      <Footer />
    </>
  );
};

export default App;
