import React, { useState } from "react";
import "./header.css";
import logo from "../pic/logo.png";

const Header = () => {
  // fixed Header
  window.addEventListener("scroll", function () {
    const header = document.querySelector(".header");
    header.classList.toggle("active", window.scrollY > 100);
  });
  // Toogle Menu
  const [Mobile, setMobile] = useState(false);
  return (
    <>
      <header className="header">
        <div className="container d_flex">
          <div className="logo">
            <img src={logo} alt="" height="60px" />
          </div>

          <div className="navlink">
            <ul
              className={Mobile ? "nav-links-mobile" : "link f_flex uppercase"}
              onClick={() => setMobile(false)}
            >
              <li>
                <a href="#heros">home</a>
              </li>

              <li>
                <a href="#services">Services</a>
              </li>

              <li>
                <a href="#career">Career</a>
              </li>
              <li>
                <a href="#contact">
                  <button className="home-btn">CONTACT US</button>
                </a>
              </li>
            </ul>

            <button className="toggle" onClick={() => setMobile(!Mobile)}>
              {Mobile ? (
                <h2 className=" close home-btn">X</h2>
              ) : (
                <h2 className="open ">III</h2>
              )}
            </button>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
