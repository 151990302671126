import React, { useState } from "react";
import "./Contact.css";

const Contact = () => {
  const [data, setData] = useState({
    fullname: "",
    phone: "",
    email: "",
    message: "",
  });

  const InputEvent = (event) => {
    const { name, value } = event.target;

    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const formSubmit = (event) => {
    event.preventDefault();
    alert("Email sent successfully");
    setData({
      fullname: "",
      email: "",
      message: "",
    });
  };
  return (
    <div className="Contact__bg">
      <section className="Contact " id="contact">
        <div className="container top">
          <div className="heading text-center">
            <h4>CONTACT</h4>
            <h1>Contact Us</h1>
          </div>

          <div className="content ">
            <div className=" box_shadow myPadding">
              <form action="https://formspree.io/f/moqzwdvd" method="POST">
                <div className="input">
                  <span>YOUR NAME</span>
                  <input
                    required
                    type="text"
                    name="fullname"
                    value={data.fullname}
                    onChange={InputEvent}
                  />
                </div>
                <div className="input">
                  <span>EMAIL </span>
                  <input
                    required
                    type="email"
                    name="email"
                    value={data.email}
                    onChange={InputEvent}
                  />
                </div>

                <div className="input">
                  <span>YOUR MESSAGE </span>
                  <textarea
                    required
                    cols="30"
                    rows="10"
                    name="message"
                    value={data.message}
                    onChange={InputEvent}
                  ></textarea>
                </div>
                <button className=" myBtnBg">SEND MESSAGE</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
