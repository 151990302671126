import React from "react";
import "./Vision.css";
import vision from "../pic/vision22.png";
import logo from "../pic/logos.png";

const Vision = () => {
  return (
    <div id="vision" className="Vision__body">
      <div className="Vision__container">
        <div className="Vision__left Vision__col">
          <div>
            <h1>Our Vision</h1>
            <p>
              <br />

              <strong>Building a Brighter Future Together</strong>
              <br />
            </p>
          </div>
          <a className="back" href="#">
            &larr; Back
          </a>
        </div>
        <div className="Vision__right Vision__col">
          {/* <h2>Our Vision</h2> */}

          <img src={logo} width="60px" height="60px" />
          <br />
          <blockquote>
            <p>
              Banking software technology services are specialized software
              solutions that help banks and financial institutions manage their
              operations and offer better services to their customers. These
              solutions can automate various tasks and provide real-time
              insights, enhancing efficiency and customer satisfaction.
            </p>
          </blockquote>
          <br />
          <br />
          <img src={vision} width="50px" height="50px" />
          <br />
          <blockquote>
            <p>
              Our vision for software engineering is to embrace agile, ethical,
              and sustainable development practices that prioritize
              human-centered design and collaboration. We envision a future
              where software development is guided by a strong sense of
              <strong>
                <span>
                  responsibility towards users, society, and the environment
                </span>
              </strong>
              , and where innovation is driven by diversity and inclusivity.
              Ultimately, our goal is to create software that enriches people's
              lives while minimizing its negative impacts.
            </p>
          </blockquote>
        </div>
      </div>
    </div>
  );
};

export default Vision;
